import { App, InjectionKey } from 'vue';
import { enableWithFakeDateTimeService } from '@/common/date/DatetimeService';
import { setupI18n } from '@/common/i18n/i18n';
import { defaultLocale, Locale } from '@/common/i18n/locale';

export const additionalAppDataProviderKey: InjectionKey<{ [key: string]: any }> = Symbol('additionalAppDataProviderKey');

const appData = {
  install: (app: App) => {
    const $el = document.querySelector('#app');
    const additionalData = JSON.parse($el?.getAttribute('data-additional') ?? '{}') ?? {};
    const locale = ($el?.getAttribute('data-locale') ?? defaultLocale) as Locale;

    if (additionalData.fakeDateTimeOptions) {
      enableWithFakeDateTimeService(additionalData.fakeDateTimeOptions);
    }

    app.provide(additionalAppDataProviderKey, additionalData);

    app.use(setupI18n(locale));
  },
};

export default appData;
